#openAccount {
  .account_shadow_box {
    .account_title {
      font-size: 24px;
      font-weight: 600;
      line-height: 1.42;
      color: $primary-purple;
      margin-bottom: 41px;
    }

    .el-form {
      .labelTitle {
        label {
          font-size: 18px;
          line-height: 1.44;
          color: $primary-green;
        }
      }

      .el-form-item {
        margin-top: 24px;
        margin-bottom: 32px;
      }

      .big_img {
        width: 192px;
        display: inline-block;
        @include rtl-sass-prop(margin-right, margin-left, 30px);
        cursor: pointer;
        padding: 3px;
        border: 1px solid transparent;

        .backgroung {
          background-color: $primary-purple;
        }

        img {
          max-width: 145px;
          margin: 10px calc((100% - 145px) / 2);
        }

        &.active {
          box-shadow: 0 8px 21px 0 rgba(0, 81, 255, 0.35);
          border: solid 1px $primary-purple;
        }
      }

      .pamm_active {
        font-size: 12px;
        color: #7e90a6;
        margin-bottom: 20px;
      }

      .currency_img {
        display: inline-block;
        @include rtl-sass-prop(margin-right, margin-left, 40px);
        cursor: pointer;
        padding: 3px;
        border: 1px solid transparent;

        &.active {
          box-shadow: 0 8px 21px 0 rgba(0, 81, 255, 0.35);
          border: solid 1px $primary-purple;
        }

        .shadow_inner img {
          width: 45px;
        }
      }

      .tnc {
        border: $container-border;
        border-radius: $container-radius;
        padding: 20px;
        margin: 40px 0;

        .checkStatus {
          /deep/ .el-form-item__content {
            line-height: 1;
          }

          /deep/ .el-checkbox__label {
            font-size: 16px;
            font-weight: 200;
            line-height: 1.56;
            color: $primary-purple;
          }

          /deep/ .el-form-item__error {
            @include rtl-sass-prop(margin-left, margin-right, 33px);
          }
        }
      }

      .pamm_tips {
        font-size: 14px;
        color: $red;
        padding-bottom: 10px;
      }

      /deep/ ul {
        padding-left: 18px;

        li {
          font-weight: 200;
          line-height: 1.56;
          color: black;
          margin-bottom: 10px;
          list-style: decimal;
          padding-left: 1em;

          &::marker {
            color: $primary-purple;
          }

          p {
            display: inline-block;
            @include rtl-sass-prop(margin-right, margin-left, 10px);
          }

          a {
            text-decoration: underline;
          }
        }
      }

      .radio {
        width: 94px;
        height: 46px;
        border-radius: 23px;
        border: solid 1px $primary-purple;
        @include rtl-sass-value(margin-left, 6px, 20px);
        @include rtl-sass-value(margin-right, 20px, 6px);
        margin-bottom: 16px;
        display: inline-block;
        font-weight: 600;
        cursor: pointer;
        color: $primary-purple;

        p {
          line-height: 46px;
          text-align: center;
        }

        &.active {
          background-color: $primary-purple;
          color: #ffffff;
          border: none;
        }
      }

      .btn_item {
        text-align: center;
      }
    }
  }
}

@media (max-width: 550px) {
  #openAccount .account_shadow_box {
    padding: 40px 24px 30px;
    .account_title {
      font-size: 20px;
      margin-bottom: 34px;
    }

    .el-form {
      .labelTitle {
        label {
          font-size: 16px;
        }
      }

      .el-form-item {
        margin-top: 22px;
      }

      .currency_img .shadow_inner img {
        width: 40px;
      }

      /deep/ .checkStatus.el-form-item {
        margin-bottom: 55px;
      }
    }
  }
}
